import { zodResolver } from '@hookform/resolvers/zod'
import { InputWrapperGridStyled } from '@theme'
import { t } from 'i18next'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import {
  IAddProvidingRequest,
  ICommonContentDetail,
  ICoreValueFormData,
} from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { z } from 'zod'

type CoreValueDetailModalProps = {
  open: boolean
  isLoading: boolean
  onClose: () => void
  title?: string
  content?: ReactNode
  onSave: (data: ICoreValueFormData) => void
  item?: ICommonContentDetail | null
  changeLoading?: (value: boolean) => void
  noDefault: number
}

const addCoreValueSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:aboutsus_Core_Values_title_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(50, {
      message: t('error:aboutsus_Core_Values_title_length_error') as string,
    }),
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:aboutsus_Core_Values_content_length_error') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:aboutsus_Core_Values_content_length_error') as string,
    }),
  no: z
    .number()
    .min(1, { message: t('error:aboutsus_Core_Values_no_error') as string }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
})

export const CoreValueDetailModal = (props: CoreValueDetailModalProps) => {
  const {
    open,
    onClose,
    title,
    onSave,
    isLoading,
    item,
    changeLoading,
    noDefault,
  } = props

  const [imageSrc, setImageSrc] = useState(item?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    item?.mediaMobile?.url ?? ''
  )
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const defaultValue: ICoreValueFormData = {
    content: '',
    contentInDutch: '',
    title: item?.title ?? '',
    titleInDutch: item?.titleInDutch ?? '',
    no: item?.no ?? noDefault,
    mediaId: item?.mediaId ? +item?.mediaId : 0,
    mediaMobileId: item?.mediaMobileId ? +item?.mediaMobileId : 0,
  }
  const { control, handleSubmit, setValue } = useForm<IAddProvidingRequest>({
    defaultValues: defaultValue,
    resolver: zodResolver(addCoreValueSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    setValue('content', item?.content ?? '')
    setValue('contentInDutch', item?.contentInDutch ?? '')
    setValue('title', item?.title ?? '')
    setValue('mediaId', item?.mediaId ?? 0)
    setValue('mediaMobileId', item?.mediaMobileId ?? 0)
    setValue('titleInDutch', item?.titleInDutch ?? '')
    setValue('no', item?.no ?? noDefault)
  }, [open])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">
            {item ? 'Core value detail' : 'Add core value'}
          </h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6">
        <form>
          <InputWrapperGridStyled>
            <Controller
              name="no"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="No"
                    name="no"
                    alignment="col"
                    min="1"
                    required
                    onChange={(e) => {
                      onChange(parseInt(e.target.value || '0', 10))
                    }}
                    value={value?.toString()}
                    inputMode={'numeric'}
                    type="number"
                    errors={error?.message}
                  />
                )
              }}
            />

            <div className="space"></div>

            <Controller
              name={'title'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Title"
                    name="title"
                    alignment="col"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name={'titleInDutch'}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Title in Netherlands"
                    name="titleInDutch"
                    alignment="col"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name="content"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Content"
                    name="content"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    alignment="col"
                    containerClassName="mt-3"
                  />
                )
              }}
            />

            <Controller
              name="contentInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    label="Content in Netherlands"
                    name="contentInDutch"
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    alignment="col"
                    containerClassName="mt-3"
                  />
                )
              }}
            />

            <div className="flex flex-col gap-1">
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="mt-3"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                      }}
                      errors={error?.message}
                      initResource={imageSrc}
                      reset={open}
                      changeLoading={changeLoading}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                }}
              />
            </div>

            <div className="flex flex-col gap-1">
              <Controller
                name="mediaMobileId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image mobile"
                      name="mediaMobileId"
                      id="mediaMobileId"
                      containerClassName="mt-3"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaMobileId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                      }}
                      errors={error?.message}
                      initResource={imageMobileSrc}
                      reset={open}
                      changeLoading={changeLoading}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={5}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                }}
              />
            </div>
          </InputWrapperGridStyled>

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className="submit__btn login-btn mr-10"
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="middle"
                className="submit__btn login-btn"
                loading={isLoading}
                onClick={handleSubmit(onSave, (e) => {
                  // console.log(e)
                })}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </AppModal>
  )
}
